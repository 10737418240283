
<template>
    <div class="container text-center">
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Autres informations</h1>
                <p  class="info">Des informations que je pourrais qualifier de "autre".</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Idées cadeaux</h1>
                <p class="info">Leurs cadeaux, c'est vous !</p>
                <p class="info">Si vous voulez tout de même en faire un, je peux vous renseigner contre un bon os de côte de boeuf !</p>
                <p class="info">D'après ce que je peux voir dans la maison, ils aiment bien :</p>
                <ul class="list-group">
                    <li class="list-group-item">Les meubles anciens (table à manger de ferme pour 8, meuble de métier, apothicaire...) <a href="https://www.moka-brocante.com/" target="_blank">Idées ici</a></li>
                    <li class="list-group-item">Les oeuvres d'art 
                        <a href="https://www.instagram.com/ohhoneysilhouettes/" target="_blank">Idées ici</a>
                        <a href="https://www.patrickpavan.fr/oeuvres" target="_blank"> ou ici</a>
                        et d'autres à venir
                    </li>

                    <li class="list-group-item">Les vinyles</li>
                    <li class="list-group-item">Les cadres photos noirs et dorés <a href="https://www.facebook.com/anne.cathala.81/?locale=fr_FR">Idées ici</a></li>
                    <li class="list-group-item">Les BDs et les livres</li>
                    <li class="list-group-item">Les chiliennes (2) tissus couleur vert de gris</li>
                    <li class="list-group-item">Une échelle (mini. 6 mètres) plus sécurisée que celle actuelle où les barreaux se cassent</li>
                    <li class="list-group-item">Les objets de curiosité, antiquités...</li>
                    <li class="list-group-item">Plantes pour dehors</li>
                    <li class="list-group-item">Un appareil photo reflex</li>
                    <li class="list-group-item">Les plantes intérieures et extérieures</li>
                    <li class="list-group-item">Les Citroën C15 (surtout mon maître)</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Habillement</h1>
                <p class="info">Pensez à prendre une petite laine pour le soir</p>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Jeux</h1>
                <p class="info">N'hésitez pas à prendre vos boules de pétanque ou molki !</p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
  
<script>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import Footer from './../components/Footer.vue'
const router = useRouter();
export default {
    components: {
        Footer
    },
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    name: 'Lodging',
    props: {},
    methods: {},
    mounted: () => {
        window.scrollTo(0,0);
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nouk {
    transition: transform 0.3s ease-in-out;
}

.nouk:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.title {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.subtitle {
    font-family: 'Afacad', sans-serif;
    font-size: 48pt;

}
.text-pg {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.info {
    font-family: 'Afacad', sans-serif;
    font-size: 18pt;
}
.amour {
    font-family: 'Afacad', sans-serif;
    font-size: 24pt;
    color: white;
}
.wouf {
    background-color: #a4b7ac;
}
.gar {
    font-family: 'Afacad', sans-serif;
    font-size: 36pt;
}
.gar-text {
    font-family:  'Afacad', sans-serif;
    font-size: 20pt;
}
.dist {
    font-family:  'Afacad', sans-serif;
    font-size: 20pt;
}
.btn {
    background-color: #4a7059;
    color: white;
    border-radius: 0;
    font-family:  'Afacad', sans-serif;
    font-size: 16pt;
}
.btn:hover {
    background-color: #4a7059;
    color: white;
}

.info {
    font-family: 'Afacad', sans-serif;
    font-size: 18pt;
}

.list-group {
    font-family: 'Afacad', sans-serif;
    font-size: 16pt;
}
</style>
  