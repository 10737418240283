
<template>
    <div class="container text-center">
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Les logements</h1>
                <p  class="info">Voici quelques logements sympas autour de Fontbelle.</p>
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Gîte de la Valette</h3>
                <p class="dist">8 minutes en voiture</p>
                <a href="https://www.tourisme-tarn.com/hebergement-locatif/le-gite-de-lavalette/" target="_blank">
                    <button class="btn mb-3">Consulter</button>
                </a>
                <p class="info">Merci de consulter mes maîtres pour passer en direct</p>
            </div>
            <div class="col-sm-6">
                <img src="https://www.tourisme-tarn.com/wp-content/uploads/wpetourisme/14120980-diaporama.jpg" class="img-fluid" alt="...">
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Logements multiples à Esperière</h3>
                <p class="dist">12 minutes en voiture</p>
                <a target="_blank" href="https://www.airbnb.fr/s/Camboun%C3%A8s--France/homes?adults=1&place_id=ChIJ98LyBRMdrhIRoUXWHpE0_FE&refinement_paths%5B%5D=%2Fhomes&tab_id=home_tab&query=Camboun%C3%A8s%2C%20France&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-02-01&monthly_length=3&price_filter_input_type=0&price_filter_num_nights=5&channel=EXPLORE&ne_lat=43.59535184497939&ne_lng=2.4646381311997914&sw_lat=43.57855734681248&sw_lng=2.444017128523825&zoom=14.943695068216527&zoom_level=14&search_by_map=true&search_type=filter_change&date_picker_type=calendar&checkin=2024-07-06&checkout=2024-07-07&source=structured_search_input_header">
                    <button class="btn mb-3">Consulter</button>
                </a>
                <p class="info">Plusieurs logements existent</p>
            </div>
            <div class="col-sm-6">
                <img src="https://a0.muscache.com/im/pictures/4290bc1f-f381-49db-a2f7-edb512f397ae.jpg?im_w=1200" class="img-fluid" alt="...">
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Gîte à Cambounès</h3>
                <p class="dist">5 minutes en voiture</p>
                <a href="https://www.airbnb.fr/rooms/706208614522662097?check_in=2024-07-06&check_out=2024-07-07&guests=1&adults=1&s=67&unique_share_id=0a589020-8661-4313-8e78-602d83f04af9" target="_blank">
                    <button class="btn mb-3">Consulter</button>
                </a>
                <p class="info">Plusieurs chambres disponibles</p>
            </div>
            <div class="col-sm-6">
                <img src="https://a0.muscache.com/im/pictures/miso/Hosting-706208614522662097/original/068ed7e3-5c70-4ddc-a1c0-6100e8db1ab3.jpeg?im_w=1440" class="img-fluid" alt="...">
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Gîtes au Bez</h3>
                <p class="dist">8 minutes en voiture</p>
                <a target="_blank" href="https://www.airbnb.fr/s/Camboun%C3%A8s--France/homes?tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-02-01&monthly_length=3&price_filter_input_type=0&channel=EXPLORE&query=Camboun%C3%A8s%2C%20France&place_id=ChIJ98LyBRMdrhIRoUXWHpE0_FE&date_picker_type=calendar&checkin=2024-07-06&checkout=2024-07-07&source=structured_search_input_header&search_type=user_map_move&price_filter_num_nights=1&ne_lat=43.61796987195752&ne_lng=2.489123167588815&sw_lat=43.601605332146875&sw_lng=2.4690224598921873&zoom=14.980563256203643&zoom_level=14.980563256203643&search_by_map=true">
                    <button class="btn mb-3">Consulter</button>
                </a>
                <p class="info">Plusieurs logements existent</p>
            </div>
            <div class="col-sm-6">
                <img src="https://a0.muscache.com/im/pictures/miso/Hosting-42981687/original/67b72566-5f91-4563-82c7-24651d16f391.jpeg?im_w=1200" class="img-fluid" alt="...">
            </div>
        </div>
        <div class="row align-items-center  mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Camping</h3>
                <p class="info">Vous pourrez planter votre tente dans un champ dédié. J'y ai souvent reniflé le passage de chevreuils !</p>
            </div>
            <div class="col-sm-6">
                <img src="https://cdn2.acsi.eu/6/5/7/d/657ddf701cd24.jpg?impolicy=gallery-detail" class="img-fluid" alt="...">
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
  
<script>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import Footer from './../components/Footer.vue'
const router = useRouter();
export default {
    components: {
        Footer
    },
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    name: 'Lodging',
    props: {},
    methods: {},
    mounted: () => {
        window.scrollTo(0,0);
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nouk {
    transition: transform 0.3s ease-in-out;
}

.nouk:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.title {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.subtitle {
    font-family: 'Afacad', sans-serif;
    font-size: 48pt;

}
.text-pg {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.info {
    font-family: 'Afacad', sans-serif;
    font-size: 18pt;
}
.amour {
    font-family: 'Afacad', sans-serif;
    font-size: 24pt;
    color: white;
}
.wouf {
    background-color: #a4b7ac;
}
.gar {
    font-family: 'Afacad', sans-serif;
    font-size: 36pt;
}
.gar-text {
    font-family:  'Afacad', sans-serif;
    font-size: 20pt;
}
.dist {
    font-family:  'Afacad', sans-serif;
    font-size: 20pt;
}
.btn {
    background-color: #4a7059;
    color: white;
    border-radius: 0;
    font-family:  'Afacad', sans-serif;
    font-size: 16pt;
}
.btn:hover {
    background-color: #4a7059;
    color: white;
}
</style>
  