
<template>
    <div class="container text-center">
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Programme</h1>
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-5">
                <img src="./../assets/pg_1.jpg" class="img-fluid" alt="...">
            </div>
            <div class="col-sm-7">
                <h3 class="text-pg">14h : Mairie d'Aiguefonde</h3>
                <h3 class="text-pg">15h : Eglise Saint Martin de Fontbelle</h3>
                <h3 class="text-pg">16h : Goûter devant l'église</h3>
                <h3 class="text-pg">18h : Promenade à Fontbelle</h3>
                <h3 class="text-pg">20h : Apéritif et repas à Fontbelle</h3>
            </div>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { useRouter} from 'vue-router';
  import Footer from './../components/Footer.vue'

  export default {
    name: 'Program',
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    components: {
        Footer
    },
    props: {},
    methods: {
        goTo: () => {
            console.log('toto')
        }
    },
    mounted: () => {
        window.scrollTo(0,0);
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
@import url('https://fonts.googleapis.com/css2?family=Afacad&display=swap');
    .nouk {
        transition: transform 0.3s ease-in-out;
    }

    .nouk:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
    .card {
        transition: transform 0.3s ease-in-out;
    }
    .card:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
    .title {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;

    }
    .text-pg {
        font-family: 'Afacad', sans-serif;
        font-size: 36pt;
        text-align: left;
    }

    
  </style>
  