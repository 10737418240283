
<template>
    <div class="container text-center">
        <div class="row">
            <div class="col">
                <h1 class="title mt-2 mb-4">Les lieux</h1>
            </div>
        </div>
        <div class="row align-items-center mt-4 mb-4">
            <div class="col-sm-6">
                <img src="https://www.lescommunes.com/images_communes/81002/065.jpg" class="img-fluid" alt="...">
            </div>
            <div class="col-sm-6">
                <h3 class="text-pg">Mairie d'Aiguefonde</h3>
                <p class="address">20 avenue de la Mairie, 81200 Aiguefonde</p>
            </div>
        </div>
        <div class="row align-items-center  mt-4 mb-4">
            <div class="col-sm-6">
                <h3 class="text-pg">Eglise de Fontbelle</h3>
                <p class="address">10 Rue de St Martin, 81260 Cambounès</p>
            </div>
            <div class="col-sm-6">
                <img src="./../assets/eglise.jpg" class="img-fluid" alt="...">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col align-self-center wouf">
                <p class="amour mb-0 pt-2 pb-2">
                    <em>Tout le reste de la journée et la de soirée se déroulera à Fontbelle juste à côté de l'église</em></p>
            </div>
        </div>
        <div class="row align-items-center  mt-4 mb-4">
            <div class="col-sm-12">
                <h3 class="subtitle mt-2 mb-4">Où se garer ?</h3>
            </div>
            <div class="col-sm-12">
                <h3 class="gar">Aiguefonde</h3>
                <p class="gar-text">Vous trouvrez des places un peu partout dans le village</p>
            </div>
            <div class="col-sm-12">
                <h3 class="gar">Fontbelle</h3>
                <p class="gar-text">Un champ sera spécialement dédié pour garer les voitures. Celui-ci est situé derrière l'église.</p>
                <img src="./../assets/plan.png" class="img-fluid" alt="...">
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
  
<script>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
import Footer from './../components/Footer.vue'
const router = useRouter();
export default {
    components: {
        Footer
    },
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    name: 'Places',
    props: {},
    methods: {},
    mounted: () => {
        window.scrollTo(0,0);
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nouk {
    transition: transform 0.3s ease-in-out;
}

.nouk:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.title {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.subtitle {
    font-family: 'Afacad', sans-serif;
    font-size: 48pt;

}
.text-pg {
        font-family: 'Afacad', sans-serif;
        font-size: 48pt;
    }
.address {
    font-family: 'Afacad', sans-serif;
    font-size: 24pt;
}
.amour {
    font-family: 'Afacad', sans-serif;
    font-size: 24pt;
    color: white;
}
.wouf {
    background-color: #a4b7ac;
}
.gar {
    font-family: 'Afacad', sans-serif;
    font-size: 36pt;
}
.gar-text {
    font-family:  'Afacad', sans-serif;
    font-size: 20pt;
}
</style>
  