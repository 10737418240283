
<template>
    <div>
        <div  class="row justify-content-evenly info">
            <div class="col-sm-3 align-self-center">
                <div class="card mt-2 mb-2" @click="pushTo('/programme')">
                    <img src="./../assets/programme.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <p class="card-text">Le programme</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 align-self-center">
                <div class="card mt-2 mb-2" @click="pushTo('/lieux')">
                    <img src="./../assets/carte.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <p class="card-text">Les lieux</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 align-self-center">
                <div class="card mt-2 mb-2" @click="pushTo('/logement')">
                    <img src="./../assets/logement.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <p class="card-text">Les logements</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 align-self-center">
                <div class="card mt-2 mb-2" @click="pushTo('/informations')">
                    <img src="./../assets/infos.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <p class="card-text">Autres informations</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col mt-5 mb-5">
                <p class="footer-text">
                    Pour toutes questions, merci de contacter mes maîtres : 
                </p>
                <p class="footer-text">
                    <b>06 68 63 63 75</b> ou  <b>06 33 02 25 82</b>
                </p>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { useRouter} from 'vue-router';
  export default {
    name: 'Footer',
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    props: {},
    methods: {
        goTo: () => {
            console.log('toto')
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
@import url('https://fonts.googleapis.com/css2?family=Afacad&display=swap');
    .nouk {
        transition: transform 0.3s ease-in-out;
    }

    .nouk:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
    .card {
        transition: transform 0.3s ease-in-out;
    }
    .card:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
    .title {
        font-family: 'Afacad', sans-serif;
        color: #4a7059;
        font-size: 64pt;

    }
    .text-explic{
        font-size: 18pt;
        font-family: 'Afacad', sans-serif;
    }
    .subtitle {
        font-family: 'Afacad', sans-serif;
        color: #4a7059;

    }
    .nouk-section {
        border:solid 2px #41403E;
        align-self:center;
        background:transparent;
        font-family: 'Afacad', sans-serif;

        font-size: 16pt;
        padding:1rem 1rem;
        margin:0 1rem;
        transition:all .5s ease;
        color:#41403E;
        letter-spacing:1px;
        outline:none;
        box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    }
    .info {
        margin-top: 5rem;
    }
    .amour {
        font-family: 'Afacad', sans-serif;
        font-size: 24pt;
        color: white;
    }
    .wouf {
        background-color: #a4b7ac;
    }
    .footer-text {
        font-family: 'Afacad', sans-serif;
        font-size: 18pt;
    }
    .card-text {
        font-family: 'Afacad', sans-serif;
        font-size: 18pt;
    }
    
  </style>
  