
import * as VueRouter from 'vue-router';
import Introduction from './../components/Introduction.vue'
import Home from './../components/Home.vue'
import Places from './../components/Places'
import Lodging from './../components/Lodging'
import Program from './../components/Program'
import Informations from './../components/Informations'

const routes = [
  { path: '/', component: Introduction },
  { path: '/home', component: Home },
  { path: '/lieux', component: Places },
  { path: '/logement', component: Lodging },
  { path: '/programme', component: Program },
  { path: '/informations', component: Informations}
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})


export default router