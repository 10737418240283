
<template>
    <div class="container  text-center">
        <div class="row">
            <div class="col-sm-6 mb-4">
                <h1 class="mt-4 mb-4 title">Mariage de mes maîtres</h1>
                <h1 class="mt-4 mb-4 subtitle">6 juillet 2024</h1>
                <div class="text-explic">
                    <p class="mb-2 mt-5">Super ! Ils se marient !
                    </p>
                    <p class="mb-5">
                        Vous trouverez ci dessous différentes rubriques, 
                        cliquez dessus et 
                        je vous donnerai toutes les informations utiles pour cette journée au poil !
                    </p>
                </div>
            </div>
            <div class="col-sm-6 mb-4">
                <img src="./../assets/nous.jpg" height="600" class="img-fluid nouk" alt="...">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col align-self-center wouf">
                <p class="amour mb-0 pt-2 pb-2">
                    <em>Wouf l'amour !</em></p>
            </div>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import { useRouter} from 'vue-router';
  import Footer from './../components/Footer.vue'
  export default {
    name: 'Home',
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    components: {
        Footer
    },
    props: {},
    methods: {
        goTo: () => {
            console.log('toto')
        }
    },
    mounted: () => {
        window.scrollTo(0,0);
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
@import url('https://fonts.googleapis.com/css2?family=Afacad&display=swap');
    .nouk {
        transition: transform 0.3s ease-in-out;
    }


    .card {
        transition: transform 0.3s ease-in-out;
    }
    .card:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
    .title {
        font-family: 'Afacad', sans-serif;
        color: #4a7059;
        font-size: 64pt;

    }
    .text-explic{
        font-size: 18pt;
        font-family: 'Afacad', sans-serif;
    }
    .subtitle {
        font-family: 'Afacad', sans-serif;
        color: #4a7059;

    }
    .nouk-section {
        border:solid 2px #41403E;
        align-self:center;
        background:transparent;
        font-family: 'Afacad', sans-serif;

        font-size: 16pt;
        padding:1rem 1rem;
        margin:0 1rem;
        transition:all .5s ease;
        color:#41403E;
        letter-spacing:1px;
        outline:none;
        box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
        border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    }
    .info {
        margin-top: 5rem;
    }
    .amour {
        font-family: 'Afacad', sans-serif;
        font-size: 24pt;
        color: white;
    }
    .wouf {
        background-color: #a4b7ac;
    }
    .footer-text {
        font-family: 'Afacad', sans-serif;
        font-size: 18pt;
    }
    .card-text {
        font-family: 'Afacad', sans-serif;
        font-size: 18pt;
    }
    
  </style>
  