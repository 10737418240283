
<template>
    <div class="container">
        <!-- Content here -->
        <div class="row">
            <div class="col" @click="pushTo('/home')">
                <img src="./../assets/nouk_1.png" class="img-fluid nouk" alt="...">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <h2 class="text-title">Salut !</h2>
                <h3 class="text">Moi c'est Nouk et je suis informatichien. </h3>
                <h3 class="text">Mes maîtres ont décidé de se marier et ils m'ont donné la mission de vous guider dans cette aventure woufesque.</h3>
                <h3 class="text"> Vous me suivez ? Alors cliquez sur moi !</h3>
            </div>
        </div>
    </div>
</template>
  
<script>
/* eslint-disable */
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
export default {
    setup() {
        const router = useRouter()
        function pushTo(route) { router.push(route) }
        return {
            pushTo
        }
    },
    name: 'Introduction',
    props: {},
    methods: {}
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nouk {
    transition: transform 0.3s ease-in-out;
}

.nouk:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.text-title {
    font-family: 'Afacad', sans-serif;
    font-size: 42pt;
    color: #4a7059;
}
.text {
        font-family: 'Afacad', sans-serif;
        font-size: 24pt;
    }
</style>
  